import { v4 as uuid } from 'uuid';
import { setAlert } from './alert';
import { ADD_USER_EVENT, ADD_USER_EVENT_FAIL } from './types';
import axios from 'axios';

// Add a user event

export const addEvent = (type, event) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({
      type,
      event,
    });
    console.log('body', body);

    const res = await axios.post(`api/v1/events`, body, config);

    dispatch({
      type: ADD_USER_EVENT,
      payload: {
        type,
        event,
      },
    });
  } catch (err) {
    console.log(err);
    const errors = err.response.data;
    dispatch(setAlert(errors.error, 'error'));

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }

    dispatch({
      type: ADD_USER_EVENT_FAIL,
    });
  }
};

// Get all userevents - not required right now

// export const getCampaigns = () => async (dispatch) => {
//   console.log('Query Campaigns');
//   try {
//     const res = await axios.get(`api/v1/campaigns`);
//     console.log(res);
//     dispatch({
//       type: CAMPAIGNS_LOAD,
//       payload: res.data,
//     });
//   } catch (err) {
//     dispatch({
//       type: CAMPAIGNS_LOAD_FAIL,
//       payload: {
//         msg: err,
//         status: err,
//       },
//     });
//   }
// };
