import React from 'react';
import styled from 'styled-components';

import {
  Grid,
  Hidden,
  List,
  ListItemText,
  ListItem as MuiListItem,
} from '@material-ui/core';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem
                component='a'
                href='https://www.pleronix.com'
                target='_blank'
              >
                <ListItemText primary='Pleronix Site' />
              </ListItem>

              <ListItem
                component='a'
                href='https://pleronix.com/termcon/'
                target='_blank'
              >
                <ListItemText primary='Terms of Service' />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify='flex-end'>
          <List>
            <ListItem>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Pleronix`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
