import { ADD_USER_EVENT, ADD_USER_EVENT_FAIL } from '../actions/types';

const initialState = {
  events: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_USER_EVENT:
      return {
        events: [...state.events, payload],
      };

    case ADD_USER_EVENT_FAIL:

    default:
      return state;
  }
}
