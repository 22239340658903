import React from 'react';

import async from '../components/Async';

import {
  // BookOpen,
  // Briefcase,
  // Calendar as CalendarIcon,
  // CheckSquare,
  // CreditCard,
  Grid,
  // Heart,
  // Layout,
  // List,
  // Map,
  Monitor,
  // ShoppingCart,
  // PieChart,
  // Sliders,
  // User,
  Users,
  Target,
  FileText,
  Crosshair,
  Activity,
  Edit3,
  Settings as Setting,
  Mail,
  MessageCircle,
} from 'react-feather';

// Auth components
const SignIn = async(() => import('../pages/auth/SignIn'));
const SignUp = async(() => import('../pages/auth/SignUp'));
const ResetPassword = async(() => import('../pages/auth/ResetPassword'));
const Page404 = async(() => import('../pages/auth/Page404'));
const Page500 = async(() => import('../pages/auth/Page500'));

// Components components
// const Alerts = async(() => import('../pages/components/Alerts'));
// const Avatars = async(() => import('../pages/components/Avatars'));
// const Badges = async(() => import('../pages/components/Badges'));
// const Buttons = async(() => import('../pages/components/Buttons'));
// const Cards = async(() => import('../pages/components/Cards'));
// const Chips = async(() => import('../pages/components/Chips'));
// const Dialogs = async(() => import('../pages/components/Dialogs'));
// const ExpPanels = async(() => import('../pages/components/ExpansionPanels'));
// const Lists = async(() => import('../pages/components/Lists'));
// const Menus = async(() => import('../pages/components/Menus'));
// const Pagination = async(() => import('../pages/components/Pagination'));
// const Progress = async(() => import('../pages/components/Progress'));
// const Snackbars = async(() => import('../pages/components/Snackbars'));
// const Tooltips = async(() => import('../pages/components/Tooltips'));

// Dashboards components
const Default = async(() => import('../pages/dashboards/Default'));
// const Analytics = async(() => import('../pages/dashboards/Analytics'));
const Campaigns = async(() => import('../pages/dashboards/CampaignBoard'));
const Tenders = async(() => import('../pages/dashboards/Tenders'));
const Projects = async(() => import('../pages/dashboards/Projects'));

// Forms components
// const Pickers = async(() => import('../pages/forms/Pickers'));
// const SelectionCtrls = async(() => import('../pages/forms/SelectionControls'));
// const Selects = async(() => import('../pages/forms/Selects'));
const TextFields = async(() => import('../pages/forms/TextFields'));
// const Dropzone = async(() => import('../pages/forms/Dropzone'));
// const Editors = async(() => import('../pages/forms/Editors'));

// Icons components
// const MaterialIcons = async(() => import('../pages/icons/MaterialIcons'));
// const FeatherIcons = async(() => import('../pages/icons/FeatherIcons'));

// Pages components
const Blank = async(() => import('../pages/pages/Blank'));
// const InvoiceDetails = async(() => import('../pages/pages/InvoiceDetails'));
const InvoiceList = async(() => import('../pages/pages/InvoiceList'));

// const Pricing = async(() => import('../pages/pages/Pricing'));
const Profile = async(() => import('../pages/pages/Profile'));
// const Settings = async(() => import('../pages/pages/Settings'));
// const Tasks = async(() => import('../pages/pages/Tasks'));
const Calendar = async(() => import('../pages/pages/Calendar'));

// Tables components
// const SimpleTable = async(() => import('../pages/tables/SimpleTable'));
// const AdvancedTable = async(() => import('../pages/tables/AdvancedTable'));

// Chart components
// const Chartjs = async(() => import('../pages/charts/Chartjs'));

// Maps components
// const GoogleMaps = async(() => import('../pages/maps/GoogleMaps'));
// const VectorMaps = async(() => import('../pages/maps/VectorMaps'));

// Documentation
// const Docs = async(() => import('../pages/docs/Documentation'));
// const Changelog = async(() => import('../pages/docs/Changelog'));
// const Presentation = async(() => import('../pages/docs/Presentation'));

//Pleronix custom

const dashboardsRoutes = {
  id: 'News',
  path: '/dashboard/default',
  // header: 'Dashboard',
  icon: <Monitor />,
  // containsHome: true,
  component: Default,
  children: null,
  // children: [
  //   {
  //     path: '/dashboard/default',
  //     name: 'Main',
  //     component: Default,
  //   },
  //   {
  //     path: '/dashboard/analytics',
  //     name: 'Insights',
  //     component: Analytics,
  //   },
  // ],
};

const tenderRoutes = {
  id: 'Tenders',
  path: '/dashboards/tenders',
  icon: <Grid />,
  component: Tenders,
  children: null,
};

const campaignRoutes = {
  id: 'Campaigns',
  path: '/dashboards/campaignboard',
  icon: <Target />,
  badge: '',
  component: Campaigns,
  children: null,
};

const reportRoutes = {
  id: 'Projects',
  path: '/dashboards/projects',
  header: 'Research',
  icon: <FileText />,
  component: Projects,
  children: null,
};

const blogRoutes = {
  id: 'Blogs',
  path: '/profile',
  icon: <MessageCircle />,
  component: Profile,
  children: null,
};
const emailRoutes = {
  id: 'Email',
  path: '/calendar',
  icon: <Mail />,
  component: Calendar,
  children: null,
};

const analysisRoutes = {
  id: 'Analysis',
  path: '/components',
  header: 'Analytics',
  icon: <Activity />,
  component: TextFields,
  children: null,
};

const trackerRoutes = {
  id: 'Tracker',
  path: '/pages',
  icon: <Crosshair />,
  component: InvoiceList,
  children: null,
};

const keywordRoutes = {
  id: 'Semantics',
  path: '/pages',
  icon: <Edit3 />,
  component: Projects,
  children: null,
};

const usersRoutes = {
  id: 'Users',
  path: '/auth',
  icon: <Users />,
  header: 'Admin',
  component: SignUp,
  children: null,
};

const settingRoutes = {
  id: 'Setting',
  path: '/pages',
  icon: <Setting />,
  component: TextFields,
  children: null,
};

// const formsRoutes = {
//   id: 'Forms',
//   path: '/forms',
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: '/forms/pickers',
//       name: 'Pickers',
//       component: Pickers,
//     },
//     {
//       path: '/forms/selection-controls',
//       name: 'Selection Controls',
//       component: SelectionCtrls,
//     },
//     {
//       path: '/forms/selects',
//       name: 'Selects',
//       component: Selects,
//     },
//     {
//       path: '/forms/text-fields',
//       name: 'Text Fields',
//       component: TextFields,
//     },
//     {
//       path: '/forms/dropzone',
//       name: 'Dropzone',
//       component: Dropzone,
//     },
//     {
//       path: '/forms/editors',
//       name: 'Editors',
//       component: Editors,
//     },
//   ],
// };

// const tablesRoutes = {
//   id: 'Tables',
//   path: '/tables',
//   icon: <List />,
//   children: [
//     {
//       path: '/tables/simple-table',
//       name: 'Simple Table',
//       component: SimpleTable,
//     },
//     {
//       path: '/tables/advanced-table',
//       name: 'Advanced Table',
//       component: AdvancedTable,
//     },
//   ],
// };

// const iconsRoutes = {
//   id: 'Icons',
//   path: '/icons',
//   icon: <Heart />,
//   children: [
//     {
//       path: '/icons/material-icons',
//       name: 'Material Icons',
//       component: MaterialIcons,
//     },
//     {
//       path: '/icons/feather-icons',
//       name: 'Feather Icons',
//       component: FeatherIcons,
//     },
//   ],
// };

// const chartRoutes = {
//   id: 'Charts',
//   path: '/charts',
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null,
// };

// const mapsRoutes = {
//   id: 'Maps',
//   path: '/maps',
//   icon: <Map />,
//   children: [
//     {
//       path: '/maps/google-maps',
//       name: 'Google Maps',
//       component: GoogleMaps,
//     },
//     {
//       path: '/maps/vector-maps',
//       name: 'Vector Maps',
//       component: VectorMaps,
//     },
//   ],
// };

// const presentationRoutes = {
//   id: 'Presentation',
//   path: '/',
//   header: 'Docs',
//   icon: <Monitor />,
//   component: Presentation,
//   children: null,
// };

// const documentationRoutes = {
//   id: 'Getting Started',
//   path: '/documentation',
//   icon: <BookOpen />,
//   component: Docs,
//   children: null,
// };

// const changelogRoutes = {
//   id: 'Changelog',
//   path: '/changelog',
//   badge: 'v1.0.8',
//   icon: <List />,
//   component: Changelog,
//   children: null,
// };

// This route is not visisble in the sidebar
const privateRoutes = {
  id: 'Private',
  path: '/private',
  component: Blank,
  children: null,
};

const authRoutes = {
  id: 'Auth',
  path: '/',
  icon: <Users />,
  children: [
    {
      path: '/',
      name: 'Sign In',
      component: SignIn,
    },
    {
      path: '/auth/sign-up',
      name: 'Sign Up',
      component: SignUp,
    },
    {
      path: '/auth/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
    },
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500,
    },
  ],
};

export const dashboard = [
  dashboardsRoutes,
  // pagesRoutes,
  // profileRoutes,
  // projectsRoutes,
  tenderRoutes,
  campaignRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // reportRoutes,
  // blogRoutes,
  // emailRoutes,
  // analysisRoutes,
  // trackerRoutes,
  // keywordRoutes,
  // usersRoutes,
  // settingRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // presentationRoutes,
  // documentationRoutes,
  // changelogRoutes,
  // privateRoutes,
];

export const auth = [authRoutes]; //will ne later removed

export default [
  dashboardsRoutes,
  // pagesRoutes,
  // profileRoutes,
  // projectsRoutes,
  tenderRoutes,
  campaignRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // authRoutes,
  // componentsRoutes,
  // reportRoutes,
  // blogRoutes,
  // emailRoutes,
  // analysisRoutes,
  // trackerRoutes,
  // keywordRoutes,
  // usersRoutes,
  // settingRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // presentationRoutes,
  // documentationRoutes,
  // changelogRoutes
];
