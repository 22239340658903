import { SET_THEME } from '../actions/types';

export default function reducer(state = { currentTheme: 0 }, actions) {
  switch (actions.type) {
    case SET_THEME:
      return {
        ...state,
        currentTheme: actions.payload,
      };

    default:
      return state;
  }
}
