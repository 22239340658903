// Theme
export const SET_THEME = 'SET_THEME';
// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
//Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
// Article load
export const ARTICLES_LOAD = 'ARTICLES_LOAD';
export const ARTICLES_ERROR = 'ARTICLES_ERROR';

// User UI/UX
// Campaign
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_CAMPAIGN_FAIL = 'ADD_CAMPAIGN_FAIL';
export const CAMPAIGNS_LOAD = 'CAMPAIGNS_LOAD';
export const CAMPAIGNS_LOAD_FAIL = 'CAMPAIGNS_LOAD_FAIL';
export const CAMPAIGN_DELETE = 'DELETE_CAMPAIGN';
export const CAMPAIGN_DELETE_FAIL = 'CAMPAIGN_DELETE_FAIL';
export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE';
export const CAMPAIGN_UPDATE_FAIL = 'CAMPAIGN_UPDATE_FAIL';

//Tenders
export const TENDERS_LOAD = 'TENDER_LOAD';
export const TENDERS_LOAD_FAIL = 'TENDER_LOAD_FAIL';

// User Events
export const ADD_USER_EVENT = 'ADD_USER_EVENT';
export const ADD_USER_EVENT_FAIL = 'ADD_USER_EVENT_FAIL';
