import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import alert from './alert';
import auth from './auth';
import article from './article';
import campaigns from './campaigns';
import tender from './tender';
import events from './events';

export default combineReducers({
  themeReducer,
  alert,
  auth,
  article,
  campaigns,
  tender,
  events,
});
