import {
  ADD_CAMPAIGN,
  CAMPAIGN_DELETE,
  USER_DATE_SELECT,
  CAMPAIGNS_LOAD,
  CAMPAIGNS_LOAD_FAIL,
  ADD_CAMPAIGN_FAIL,
  CAMPAIGN_DELETE_FAIL,
  CAMPAIGN_UPDATE,
  CAMPAIGN_UPDATE_FAIL,
} from '../actions/types';

const initialState = {
  campaigns: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CAMPAIGN:
      return {
        campaigns: [...state.campaigns, payload],
      };
    case CAMPAIGN_DELETE:
      return {
        campaigns: [
          ...state.campaigns.filter((campaign) => campaign.campId !== payload),
        ],
      };
    case CAMPAIGNS_LOAD:
      return {
        ...state,
        campaigns: payload.data,
      };

    case CAMPAIGN_UPDATE:
      const index = state.campaigns.findIndex(
        (item) => item.campId == payload.data.campId
      );

      const opp = state.campaigns.splice(index, 1, payload.data);
      return {
        campaigns: [...state.campaigns],
      };

    case CAMPAIGNS_LOAD_FAIL:
    case CAMPAIGN_UPDATE_FAIL:
    case CAMPAIGN_DELETE_FAIL:

    default:
      return state;
  }
}
